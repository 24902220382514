<template>
  <div class="checkout-screen">
    <div class="container">
      <CRow class="pt-5">
        <CCol md="8" class="m-auto">
          <div v-if="detailShop.payment_card == 2">
            <input type="radio" id="one" value="1" v-model="picked" />
            <label class="ml-2" for="one">Stripe</label>
          </div>
          <div
            v-bind:class="{
              'd-none': detailShop.payment_card == 0 || picked != 1,
            }"
          >
            <div class="row input-card">
              <div>
                <input
                  type="checkbox"
                  id="checkbox"
                  v-model="userExistCard"
                  class="check-box-card"
                />
                <label for="checkbox">user existing card</label>
              </div>
              <div class="col-5" v-if="userExistCard">
                <select v-model="paymentIdSelect" class="select-card">
                  <option
                    v-for="(option, index) in paymentInfos"
                    v-bind:value="option.id"
                    :key="index"
                  >
                    {{
                      option.card_number +
                      "   expiration:" +
                      option.card_expiration_date
                    }}
                  </option>
                </select>
              </div>
              <div class="m-auto col-7" v-if="!userExistCard">
                <stripe-element-card
                  ref="elementRef"
                  :pk="pulishableKey"
                  :hidePostalCode="true"
                  @token="tokenCreated"
                />
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="margin-top: 40px"
                >
                  <CButton class="checkout-btn mx-2" @click="submit"
                    >Charge</CButton
                  >
                </div>
              </div>
            </div>
          </div>
          <template>
            <div v-if="detailShop.payment_card == 2">
              <input type="radio" id="two" value="0" v-model="picked" />
              <label class="ml-2" for="two">Paypal</label>
            </div>
            <div
              class="custom-button-paypal"
              v-bind:class="[
                detailShop.payment_card == 1 || picked != 0
                  ? 'd-none'
                  : 'd-flex justify-content-center align-items-center',
              ]"
              v-on:click="onetimePaypal()"
            >
              <img class="p-2" :src="`${paypal}`" />
            </div>
            <div
              class="d-flex justify-content-center align-items-center mt-3 mb-5"
            >
              <CButton class="cancel-btn mx-2" @click="redirectCart()"
                >Cancel</CButton
              >
            </div>
          </template>
        </CCol>
        <CCol md="4">
          <div class="h2 mb-3">Checkout</div>
          <div class="checkout mb-3">
            <div class="prices py-3 px-4">
              <div class="temp-price">
                <div class="price-title">Point Use:</div>
                <div class="price-num">¥{{ totalPointUse }}</div>
              </div>
              <div class="temp-price mt-2">
                <div class="price-title">Points Received:</div>
                <div class="price-num">¥{{ totalPointBonus }}</div>
              </div>
              <div class="temp-price mt-2">
                <div class="price-title">Total Prices:</div>
                <div class="price-num">¥{{ totalPrice }}</div>
              </div>
              <div class="prices-total py-3 px-4">
                <div class="price">
                  <div class="price-title">Total Payment:</div>
                  <div class="price-num">
                    ¥{{ totalPayment }}<span class="vat"> (税込)</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="prices-total py-3 px-4">
              <div class="price">
                <div class="price-title">Total:</div>
                <div class="price-num">
                  ¥{{ price - discount }}<span class="vat"> (税込)</span>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import paypal from "@/assets/img/paypal.svg";

export default {
  components: {
    StripeElementCard,
  },
  data() {
    return {
      paymentIdSelect: null,
      userExistCard: false,
      totalPrice: 0,
      totalPointUse: 0,
      totalPointBonus: 0,
      totalPayment: 0,

      token: null,
      price: 0,
      discount: 0,
      point: 0,
      point_use: 0,
      pulishableKey: null,
      loaded: false,
      paidFor: false,
      picked: null,
      paypal: paypal,
      orders: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
      ],
      currentPage: 1,
      maxPerPage: 2,
      showReadMore: true,
      point_user: 0,
    };
  },
  created() {
    const shop_id = localStorage.getItem(Constants.SHOP_ID);
    this.point_user = localStorage.getItem(Constants.POINT);
    this.pulishableKey = process.env.VUE_APP_STRIPE;
    this.getListCart({
      shop_id,
    });
    this.getShopById(shop_id);
    const { params } = this.$route;
    let orders = params.orders;
    this.totalPrice = orders.order.total_amount;
    this.totalPointUse = orders.order.total_point_use;
    this.totalPointBonus = orders.order.total_point_received;
    this.totalPayment = orders.order.total_payment_amount;
  },
  watch: {
    listCart() {
      if (this.listCart.length) {
        let listPrice = this.listCart.map((item) => parseInt(item.price));
        this.price = listPrice.reduce(this.sum);
        let listDiscout = this.listCart.map(function (item) {
          if (item.discount) {
            return parseInt(item.price * (item.discount / 100));
          }
          return 0;
        });
        this.discount = listDiscout.reduce(this.sum);
        let listPoint = this.listCart.map(function (item) {
          if (item.point) {
            return parseInt(item.point);
          }
          return 0;
        });
        this.point = listPoint.reduce(this.sum);
      }
    },
    detailShop() {
      if (this.detailShop.payment_card != 2) {
        this.picked = this.detailShop.payment_card;
      }
    },
  },
  computed: {
    ...mapGetters(["listCart", "detailShop", "paymentInfos"]),
    // handle function load more
    totalResults() {
      return Object.keys(this.orders).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    pageOffest() {
      return this.maxPerPage * this.currentPage;
    },
    paginatedOrders() {
      return this.orders.slice(0, this.currentPage * this.maxPerPage);
    },
    // end handle function show more
  },
  methods: {
    ...mapActions({ getListCart: "getListCart" }),
    ...mapActions({ getShopById: "getShopById" }),
    ...mapActions({ createOrder: "createOrder" }),
    sum(total, num) {
      return total + num;
    },
    submit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      const shop_id = localStorage.getItem(Constants.SHOP_ID);
      const formData = {
        order: {
          shop_id: shop_id,
          payment_id: token.id,
          payment_type: 1,
          status_order: 0,
          total_amount: this.price,
          total_payment_amount: this.price - this.discount - this.point,
          total_point_use: this.point_use,
          total_point_received: this.point,
        },
        order_details: [],
      };

      formData.order_details = this.listCart.map((item) => ({
        content_id: item.id,
        price: item.price,
        payment_amount: item.price - (item.price * item.discount) / 100,
        discount: item.discount,
        point_use: 0,
        points_received: item.point,
      }));
      this.createOrder(formData);
    },
    redirectCart() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "default cart"
          : "default cart domain",
      });
    },
    onetimePaypal() {
      this.$loading(true);
      const shop_id = localStorage.getItem(Constants.SHOP_ID);
      const formData = {
        order: {
          shop_id: shop_id,
          payment_type: 0,
          status_order: 0,
          total_amount: this.price,
          total_point_use: this.point_use,
          total_point_received: this.point,
          total_payment_amount: this.price - this.discount - this.point_use,
        },
        order_detail: [],
      };
      formData.order_detail = this.listCart.map((item) => ({
        content_id: item.id,
        price: item.price - (item.price * item.discount) / 100,
        payment_amount: item.price - (item.price * item.discount) / 100,
        discount: item.discount,
        point_use: 0,
        points_received: item.point,
      }));
      Api.userRequestServer
        .post(`/paypal/onetime-redirect`, formData)
        .then((response) => {
          const { data } = response;
          window.location.href = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMore() {
      this.currentPage += 1;
    },
  },
};
</script>
<style lang="scss">
.checkout-screen {
  background-color: #f5f5f5;
  min-height: calc(100vh - 280px);
  .card {
    background: #7a95c6;
    color: white;
    justify-content: center;
    margin: auto;
  }
  #stripe-element-mount-point {
    border: 1px solid blue;
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .custom-button-paypal {
    background: #ffc439;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    &:hover {
      cursor: pointer;
      background: #fcb208;
    }
  }
  .checkout-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #635bff;
  }
  .cancel-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
  .showmore-btn {
    width: auto;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #e19c9b;
  }
  .input-card {
    margin-left: 40px;
    .check-box-card {
      margin: 5px;
      margin-right: 10px;
    }
    .select-card {
      width: 240px;
    }
  }
}
</style>
